/* Config */
$color-bg: #1e1e1e;
$color-particle: #fff;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;
$particles: 400;

/* mixins */
@function particles($max) {
  $val: 0px 0px $color-particle;
  @for $i from 1 through $max {
    $val: #{$val}, random($spacing)+px random($spacing)+px $color-particle;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}





.page-wrapper {
  position: absolute;
  z-index: 200000000000000;
}

.page-bg, .animation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-bg {
  background: $color-bg;
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
  z-index: 200000;
}

.particle, .particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}

.particle-1 {
  animation: animParticle $time-1 linear infinite;
  @include particles($particles);
  height: 2px;
  width: 2px;
}

.particle-1:after {
  @include particles($particles);
  height: 2px;
  width: 2px;
  position: a;
  width: 100%;
}

.particle-2 {
  animation: animParticle $time-2 linear infinite;
  @include particles($particles);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles($particles);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles($particles);
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite;
  @include particles($particles);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles($particles);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY($spacing * -1);
  }
}

h4 {
  color: #fff;
  font-family: Monoton;
  animation: neon1 1.5s ease-in-out infinite alternate;
}

h4:hover {
  color: #FF1177;
  animation: none;
}

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff,
      0 0 20px #fff,
      0 0 30px #fff,
      0 0 40px #FF1177,
      0 0 70px #FF1177,
      0 0 80px #FF1177,
      0 0 100px #FF1177,
      0 0 150px #FF1177;
  }
  to {
    text-shadow: 0 0 5px #fff,
      0 0 10px #fff,
      0 0 15px #fff,
      0 0 20px #FF1177,
      0 0 35px #FF1177,
      0 0 40px #FF1177,
      0 0 50px #FF1177,
      0 0 75px #FF1177;
  }
}
